import Vue from 'vue'

import VueRouter from 'vue-router'
import RangeSlider from 'vue-range-slider'

import Autocomplete from './components/vue2-autocomplete' // This is a custom fork from the autocomplete package
import Mapkit from './components/Mapkit.vue'

Vue.use(VueRouter)

var ProductTypes = Vue.component('product-types', {template: ''}),
		ProductGroupings = Vue.component('product-groupings', {template: ''}),
		Products = Vue.component('products', {template: ''}),
		z = 100,
		router;
		// app;
"use strict";


function main(callback) {
	if (router.app.productTypes) {
			callback();
	} else {
			hijax('get', window.urlProductTypes,
					null, function (data) {
							router.app.productTypes = JSON.parse(data).items;
							hijax('get', window.urlMakesModels,
									null, function (data) {
											var makemodels = JSON.parse(data);
											router.app.makes = makemodels.makes;
											router.app.models = makemodels.models;
											// Replace with hash instead of get to be able to manipulate URL?
											hijax('get', window.urlFilters,
													null, function (data) {
															router.app.machineFilters = JSON.parse(data).items;
															callback();
													}
											);
									}
							);
					}
			);

			hijax('get', window.urlProspect,
					null, function (data) {
							var i, n, l, cartIds = [], prospect = JSON.parse(data);

							for (i = 0, l = prospect.item.items.length; i < l; i++) {
									n = prospect.item.items[i];
									if (n.article.indexOf('mapkit-') == 0) {
											var prospect_id = n.id;
											hijax('get',
													window.urlMapkits + '?id=' + n.article.split('mapkit-')[1],
													null,
													function (data, c) {
															var jsonData = JSON.parse(data);
															if (jsonData.items.length) {
																	addMapkitToCartAfterReload(jsonData.items[0], prospect_id);
															}
													}
											);
									} else {
											router.app.cartIds.push(n.article);
											router.app.chosenProducts.push(prospect.item.items[i]);
									}
							}
					}
			);
	}
}

function hijax(method, uri, data, onSuccess, onError) {
	var csrftoken, client = new XMLHttpRequest();

	router.app.loading = true;

	if (onError == undefined) {
		function onError(status, response) {
					console.log(status);
					console.log(response);
			}
	}
	if (!router.app.hijaxers) {
			router.app.hijaxers = {};
	}
	router.app.hijaxers[uri.split('?')[0]] = client;
	client.open(method, uri, true);

	if (method.toLowerCase() == 'post') {
			client.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
	}

	if ('get head trace options'.indexOf(method.toLowerCase()) == -1) {
			csrftoken = parseCSRFToken();
			if (csrftoken) {
					client.setRequestHeader("X-CSRFToken", csrftoken);
			}
	}

	client.onload = function () {
			if (client.status >= 200 && client.status < 400) {
					onSuccess(client.responseText, client);
					router.app.loading = false;
			} else {
					onError(client.status, client.responseText);
					router.app.loading = false;
			}

			delete router.app.hijaxers[uri];

			if (Object.keys(router.app.hijaxers).length == 0) {
					router.app.loading = false;
			}
	};

	client.send(data);
};

function parseCSRFToken() {
	var name = 'csrftoken';
	var cookieValue = null;
	if (document.cookie && document.cookie !== '') {
			var cookies = document.cookie.split(';');
			for (var i = 0; i < cookies.length; i++) {
					var cookie = cookies[i].trim();
					if (cookie.substring(0, name.length + 1) === (name + '=')) {
							cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
							break;
					}
			}
	}
	return cookieValue;
};

function prepareFormData() {
	var fields = ['first_name', 'last_name', 'email', 'phone', 'comment', 'tradeshow', 'accept_copy'],
			data = [],
			i = fields.length;

	while (i--) {
			if (router.app['form_' + fields[i]]) {
					data.push(fields[i] + '=' + encodeURIComponent(router.app['form_' + fields[i]]));
			}
	}

	return data.join('&');
};


// Common ajax calls
// ------------------------------------------------------------------------

function getOptionGroupsFromBackend(preset) {
	hijax('get',
			window.urlOptionGroups.replace('FIXME', router.app.chosenProductTypeSystem),
			null, function (data) {
					var jsonData = JSON.parse(data);

					if (jsonData.items.length) {
							router.app.optionGroups = jsonData.items;

							router.app.optionValues = {};
							router.app.optionWCValues = [];
							if (restoreQueryParams(preset)) {
									syncDisabledOptions();
									getProductsFromBackend();
							}
					}
			}
	);
};

function getMapkitsFromBackend(id, callback) {
	if (router.app.hijaxers['/mapkits']) {
			router.app.hijaxers['/mapkits'].abort();
			delete router.app.hijaxers['/mapkits'];
	}
	var params = [], tt;

	for (var filter in app.chosenFilters) {
			params.push(filter + '=' + app.chosenFilters[filter].slug)
			if (app.chosenFilters[filter].generation) {
					params.push('generation=' + app.chosenFilters[filter].generation)
			}
	}

	if (id) {
			params.push('id=' + id)
	}

	hijax('get',
			window.urlMapkits + '?' + params.join('&'),
			null,
			function (data, c) {
					var jsonData = JSON.parse(data),
							tt = document.querySelector('.tooltip'),
							append = c.responseURL.split('?')[1].match('offset');
					if (callback) {
							callback(jsonData.items);
					} else {
							if (tt) {
									tt.classList.add('reveal');
							}
							if (Object.keys(jsonData.items).length) {
									router.app.mapkitFilters = jsonData.items.pop();
									router.app.mapkits = jsonData.items;
							} else {
									router.app.mapkits = null;
									router.app.mapkitFilters = null;
							}
					}
			}
	);

	return false;
};

function addMapkitToCartAfterReload(mapkit, id) {
	if (mapkit.products.length && router.app.cartIds.indexOf('mapkit-' + mapkit.id) == -1) {
			var mapkitJson = {
					'id'                :id,
					'article'           :'mapkit-' + mapkit.id,
					'name'              :mapkit.makemodel.name,
					'designation'       :mapkit.makemodel.name,
					'image_url'         :'/static/images/product-types/accessory-kit.jpg',
					'pretty_price'      :mapkit.pretty_price,
					'requirements'      :[],
					'recommendations'   :[],
					'mapkit'            :true,
					'products'          :mapkit.products
			};
			router.app.cartIds.push('mapkit-'+mapkit.id);
			router.app.chosenProducts.push(mapkitJson);
			router.app.updateRelatedProducts();

			var path = '/' + window.location.search;
	}
}

function getProductsFromBackend(appendAfter, filter_product_type, limit) {
	if (router.app.hijaxers['/products']) {
			router.app.hijaxers['/products'].abort();
			delete router.app.hijaxers['/products'];
	}
	var params = [], i, k, os, tt;

	os = router.app.optionsSet;

	var filter_products = Object.keys(router.app.chosenFilters).length !== 0;

	delete os.limit;
	if (limit) {
			params.push('limit=' + limit);
	}
	// First time fetching products for chosen filters, limit to 5 for quicker response
	else if (filter_products) {
			params.push('limit=' + router.app.productsListingLimit);
	} else {
			params.push('limit=' + router.app.productsLimit);
	}

	if (filter_products) {
			params.push('group=true');
			if (router.app.chosenFilterProducts) {
					params.push('products=' + router.app.chosenFilterProducts);
			}
	}

	for (k in os) {
			if (os.hasOwnProperty(k)) {
					params.push(k + '=' + os[k]);
			}
	}

	if (appendAfter != undefined) {
			params.push('offset=' + appendAfter);
	}

	if (app.displayPriceChange) {
			params.push('next_gross_price=1');
	}

	if (filter_product_type) {
			params.push('product_type=' + filter_product_type);
			params.push('product_type_skip=' + router.app.chosenGroupedProducts[filter_product_type].items.length);
	}

	hijax('get',
			window.urlProducts.replace("FIXME", (router.app.chosenProductTypeSystem ? router.app.chosenProductTypeSystem : 'all')) + '?' + params.join('&'),
			null,
			function (data, c) {
					var jsonData = JSON.parse(data),
							tt = document.querySelector('.tooltip'),
							append = c.responseURL.split('?')[1].match('offset');
					if (tt) {
							tt.classList.add('reveal');
					}
					if (filter_products) {
							if (filter_product_type) {
									router.app.chosenGroupedProducts[filter_product_type] = jsonData.items[filter_product_type];
							} else {
									router.app.chosenGroupedProducts = jsonData.items;
							}
							var jsonFilteredProducts = jsonData.filtered_products;
							if (jsonFilteredProducts.length) {
									var filterProductsProducttypes = [];
									for (var i = 0; i < jsonFilteredProducts.length; i++) {
											router.app.addProductToCart(jsonFilteredProducts[i]);
											if (filterProductsProducttypes.indexOf(jsonFilteredProducts[i].product_type.slug) === -1) {
													filterProductsProducttypes.push(jsonFilteredProducts[i].product_type.slug);
											}
									}
									router.app.setCurrentFilter({'slug':filterProductsProducttypes.join(',')}, {'id':'producttypes'});
							}
					} else if (jsonData.items.length) {
							if (append) {
									router.app.products = router.app.products.concat(jsonData.items);
							} else {
									router.app.products = jsonData.items;
							}
					} else {
							if (!append) {
									router.app.products = [];
									router.app.aborted = true;
									router.app.displayAside = true;
							}
					}

					router.app.productsTotal = jsonData.total ? parseInt(jsonData.total, 10) : 0;
					router.app.noMoreProducts = router.app.products ? router.app.products.length == router.app.productsTotal : false;
			}
	);

	return false;
};

function getProductsFromBackendByItemnumber(itemnumber) {
	hijax('get',
			window.urlProducts.replace('FIXME', 'unique') + '?itemno=' + itemnumber,
			null, function (data) {
					var parsedData = JSON.parse(data);

					if (typeof parsedData.product_group_base !== 'undefined') {
							router.app.chosenProductGrouping        = parsedData.product_group_base;
							router.app.chosenProductGroupingName    = parsedData.product_group_base;
							if (typeof parsedData.product_group_specific !== 'undefined') {
									var productGroupingValue = parsedData.product_group_specific.split(':');
									router.app.chosenProductGrouping        = productGroupingValue[0];
									router.app.chosenProductGroupingName    = productGroupingValue[1];
							}
					} else {
							router.app.chosenProductGrouping        = null;
							router.app.chosenProductGroupingName    = null;
					}

					router.app.chosenProductType            = parsedData.product_type;
					router.app.chosenProductTypeSystem      = parsedData.product_type_system;
					router.app.optionGroups                 = parsedData.option_groups;
					router.app.optionValues                 = parsedData.option_values;
					router.app.optionWCValues               = parsedData.option_wildcards;
					router.app.chosenProductTypeHelpSections= parsedData.help_sections.items;
					router.app.products                     = parsedData.items;
			}
	);
};

function getProductsFromBackendByItemnumbers(itemnumbers, callback) {
	hijax('get',
			window.urlProducts.replace("FIXME",'all') + '?itemnumbers=' + itemnumbers,
			null, function(data) {
					var list = JSON.parse(data);
					if (list.items) {
							callback(list.items);
					}
			}
	);
};

function addProductsFromBackendByItemnumbers(itemnumbers) {
	var items = itemnumbers.split(',');
	var notFoundItems = [];
	items.forEach(function(id) {
			if (!router.app.cartIds.includes(id)) {
					hijax('post', window.urlProspectItems.replace('FIXME', id), 'csrf', function (data) {
							router.app.chosenProducts.push(JSON.parse(data).item);
							router.app.updateRelatedProducts();
					}, function (data) {
							console.log(data, id);
							if (data === 404) {
									notFoundItems.push(id)
							}
					});
			}
	});
	if (notFoundItems.length) {
			alert('These items could not be found: ' + notFoundItems.toString());
	}
};

function getTradeshowsFromBackend() {
	hijax('get',
			window.urlTradeshows,
			null,
			function (data, c) {
					var jsonData = JSON.parse(data);
					if (jsonData.length) {
							router.app.form_tradeshowOptions = jsonData;
					} else {
							router.app.form_tradeshowOptions = [];
							router.app.form_tradeshow = null;
					}
			}
	);
	return false;
};

function sendFormToBackend(url, data) {
	hijax('post', url, data, function (res) {
			router.app.success = true;
			window.scrollTo(0, 0);
			router.app.form_first_name = null;
			router.app.form_last_name = null;
			router.app.form_email = null;
			router.app.form_phone = null;
			router.app.form_comment = null;
			// Remember tradeshow until next time,
			// otherwise do this:
			// router.app.form_tradeshow = null;
			// router.app.form_tradeshowOptions = [];
			router.app.formErrors = [];
	}, function (code, res) {
			var errors = JSON.parse(res), fields = [];

			if ('email' in errors) {
					fields.push('email_required');
			}

			if ('phone' in errors) {
					fields.push('phone_required');
			}

			if ('first_name' in errors) {
					fields.push('name_required');
			}

			if ('last_name' in errors) {
					fields.push('last_name_required');
			}

			router.app.formErrors = fields;
	});
};

function syncDisabledOptions() {
	var o, i, params = [], os = router.app.optionsSet;

	for (o in os) {
			if (os.hasOwnProperty(o)) {
					params.push(o + '=' + encodeURIComponent(os[o]));
			}
	}

	if (!params.length) {
			return;
	}

	i = router.app.optionGroups.length;
	while (i--) {
			o = router.app.optionGroups[i];
			hijax('get', o.autocomplete_url + '?meta=1&name=&' + params.join('&'), null, function (data, c) {
					var res = JSON.parse(data);

					if (!res.options_exists) {
							router.app.disabledOptions.push(c.responseURL.match(/options\/(.+)\?/)[1]);
					} else {
							router.app.disabledOptions = router.app.disabledOptions.filter(function (i) {
									return i != c.responseURL.match(/options\/(.+)\?/)[1];
							});
					}
			});
	}
};


// State setter functions
// ------------------------------------------------------------------------

function setProductGrouping(pname, cpg, groupingName) {
	var route = 'products',
			params = {slug: router.app.chosenProductTypeSystem};

	router.app.chosenProductGroupingName = cpg;
	router.app.chosenProductGrouping = groupingName ? groupingName : null;

	if (router.app.chosenProductGrouping) {
			route = 'gproducts';
			params.grouping = router.app.chosenProductGrouping + ':' + router.app.chosenProductGroupingName;
	}

	router.push({
			name: route,
			params: params,
			query: router.app.weight ? {weight: router.app.weight} : {}
	});
};


// State restoration functions
// ------------------------------------------------------------------------

function restoreProductType() {
	var values = window.location.pathname.split('/');

	if (!router.app.chosenProductType) {
			router.app.chosenProductType = restoreProductTypeName(values[1]);
			router.app.chosenProductTypeSystem = values[1];
	}
	if (!router.app.chosenProductGrouping) {
			if (values.length > 2) {
					router.app.chosenProductGrouping = values[2].split(':')[0];
					router.app.chosenProductGroupingName = values[2].split(':')[1];
			}
	}
};

function restoreProductTypeName(system) {
	if (!router.app.productTypes) {
			return;
	}

	var i = router.app.productTypes.length;

	while (i--) {
			if (router.app.productTypes[i].name == system) {
					router.app.chosenProductTypeHelpName = router.app.productTypes[i].help_sections.name;
					router.app.chosenProductTypeHelpDescription = router.app.productTypes[i].help_sections.description;
					router.app.chosenProductTypeHelpSections = router.app.productTypes[i].help_sections.items;
					return router.app.productTypes[i].plural_title;
			}

	}
};

function restoreQueryParams(params) {
	var wc = [], o = {}, k, e;

	clearTimeout(window.wait);

	for (k in params) {
			if (params.hasOwnProperty(k)) {
					if (params[k] === '*') {
							wc.push(k);
					} else {
							o[k] = params[k];
					}
			}
	}

	router.app.optionWCValues = wc;
	router.app.optionValues = o;

	return true;
};

function restoreWeightParam(params) {
	if (params.weight) {
			router.app.weight = parseFloat(params.weight);
	}
};


// Field focus functions
// ------------------------------------------------------------------------

function blurHilightedField() {
	var i, existing;

	existing = document.querySelectorAll('.is-field-hilighted');
	if (existing) {
			i = existing.length;
			while (i--) {
					existing[i].classList.remove('is-field-hilighted');
					existing[i].style.zIndex = '';
			}
	}

	router.app.fieldFocused = false;
};

router = new VueRouter({
	mode: 'history',
	routes: [
			{
					name: 'types',
					path: '/',
					component: ProductTypes,
					beforeEnter: function (to, from, next) {
							main(function () {
									if (!to.query) {
											window.scrollTo(0, 0);
											restoreWeightParam(to.query);

											router.app.displayCart = false;

											router.app.productGroupings = null;
											router.app.chosenFilterProducts = null;
											router.app.chosenProductsRelationsRequirements = [];
											router.app.chosenProductsRelationsRecommendations = [];
											router.app.chosenProductType = null;
											router.app.chosenProductTypeSystem = null;
											router.app.chosenProductGrouping = null;
											router.app.products = null;
											router.app.optionWCValues = [];
											router.app.optionGroups = null;
											router.app.optionValues = null;
									}

									next();
							});
					}
			},
			{
					name: 'groupings',
					path: '/:slug',
					component: ProductGroupings,
					beforeEnter: function (to, from, next) {
							main(function () {
									var url;
									window.scrollTo(0, 0);
									restoreWeightParam(to.query);

									router.app.displayCart = false;
									router.app.optionGroups = null;
									router.app.products = null;
									router.app.chosenProductGrouping = null;
									router.app.chosenProductGroupingName = null;

									restoreProductType();

									if (!router.app.productGroupings) {
											url = window.urlProductGroupings.replace('FIXME', to.params.slug);

											if (router.app.weight) {
													url += '?weight=' + router.app.weight;
											}

											hijax('get', url, null, function (data) {
															var items = JSON.parse(data).items;
															if (items.length) {
																	router.app.productGroupings = JSON.parse(data).items;
															}
													}
											);
									}

									next();
							});
					}
			},
			{
					name: 'products',
					path: '/:slug/products',
					component: Products,
					beforeEnter: function (to, from, next) {
							main(function () {
									var values;
									window.scrollTo(0, 0);

									router.app.displayCart = false;

									if (!router.app.chosenProductType) {
											values = window.location.pathname.split('/');

											router.app.chosenProductType = restoreProductTypeName(values[1]);
											router.app.chosenProductTypeSystem = values[1];
									}

									getOptionGroupsFromBackend(to.query);

									next();
							});
					}
			},
			{
					name: 'gproducts',
					path: '/:slug/:grouping/products',
					component: Products,
					beforeEnter: function (to, from, next) {
							main(function () {
									window.scrollTo(0, 0);

									router.app.displayCart = false;

									restoreProductType();
									getOptionGroupsFromBackend(to.query);

									next();
							});
					}
			}
	]
});

router.afterEach(function (to, from) {
	restoreWeightParam(to.query);
	router.app.getPriceChange();

	if (from.name && from.name.match(/products/) && to.name.match(/products/)) {
			restoreQueryParams(to.query);
			getProductsFromBackend();
			syncDisabledOptions();
	} else if (to.query) {
			var filters = [];
			if (to.query.mapkit) {
					getMapkitsFromBackend(to.query.mapkit, function(items) {
							if (items.length) {
									router.app.addMapkitToCart(items[0]);
							} else {
									alert('Invalid Mapkit ID');
							}
					});
			}
			if (to.query.products) {
					router.app.chosenFilterProducts = to.query.products;
			}
			if (to.query.make) {
					filters.push({
							'id':'make',
							'slug':to.query.make,
							'name':to.query.make,
					})
					if (to.query.model) {
							var modelFilter = {
									'id':'model',
									'slug':to.query.model,
									'name':to.query.model
							};
							if (to.query.generation) {
									modelFilter['generation'] = to.query.generation;
							}
							filters.push(modelFilter);
					} else if (router.app.chosenFilters['model']) {
							router.app.clearField(null, 'model');
					}
			}
			if (to.query.producttypes) {
					filters.push({
							'id':'producttypes',
							'slug':to.query.producttypes,
							'name':to.query.producttypes,
					});
			}
			if (filters.length) {
					router.app.setAllFilters(filters);
			}
			if (to.query.itemno) {
					getProductsFromBackendByItemnumber(to.query.itemno);
			}
			else if (to.query.items) {
					addProductsFromBackendByItemnumbers(to.query.items);
			}
	} else {
			if (Object.keys(router.app.chosenFilters).length) {
					router.app.clearField(null, 'make');
					router.app.clearField(null, 'model');
					router.app.mapkits = null;
					router.app.products = null;
			}
			router.app.chosenFilters = {};
	}

	router.app.updateRelatedProducts();
});

const app = new Vue({
	el: '#app',

	router: router,
	components: {
		'mapkit': Mapkit,
		'autocomplete': Autocomplete,
		'range-slider': RangeSlider
	},

	data: {
			maxWeight: 40,
			aborted: false,
			chosenMapkits: [],
			chosenProducts: [],
			chosenProductsRelationsRequirements: [],
			chosenProductsRelationsRecommendations: [],
			cartIds: [],
			chosenFilters: [],
			chosenFiltersProducttypes: [],
			chosenFilterProducts: null,
			chosenGroupedProducts: {},
			chosenMapkitFilters: {},
			chosenProductType: null,
			chosenProductTypeSystem: null,
			chosenProductTypeRelations: null,
			chosenProductTypeHelpName: null,
			chosenProductTypeHelpDescription: null,
			chosenProductTypeHelpSections: null,
			chosenProductGrouping: null,
			chosenProductGroupingName: null,
			hijaxers: {},
			noMoreProducts: true,
			disabledOptions: [],
			acceptTos: false,
			displayHelp: false,
			displayTerms: false,
			displayCart: false,
			displaySendListForm: false,
			displayPriceChange: false,
			priceChange: {},
			loading: false,
			displayAside: false,
			displayTT: true,
			fieldFocused: false,
			formErrors: [],
			editWeight: false,
			form_first_name: null,
			form_last_name: null,
			form_email: null,
			form_phone: null,
			form_comment: null,
			form_accept_copy: null,
			form_tradeshow: null,
			form_tradeshowOptions: [],
			success: false,
			optionGroups: null,
			optionValues: null,
			optionWCValues: [],
			mapkits: null,
			mapkitFilters: null,
			choiceOpen: false,
			products: null,
			productGroupings: null,
			productFilters: {},
			productTypes: null,
			productsTotal: null,
			productsLimit: 20,
			productsListingLimit: 5,
			optionsLimit: 999,
			recentProductChosen: null,
			recentProductChosenTypeSystem: null,
			makes: {},
			models: {},
			machineFilters: {},
			weight: 0,
			weightEnabled: true
	},
	computed: {
			optionsSet: function () {
					var k, os = {}, i = router.app.optionWCValues.length;

					os.limit = router.app.optionsLimit;

					if (router.app.chosenProductGrouping) {
							os.grouping = router.app.chosenProductGrouping;
					}

					while (i--) {
							os[router.app.optionWCValues[i]] = '*';
					}

					for (k in router.app.optionValues) {
							if (router.app.optionValues.hasOwnProperty(k) && router.app.optionValues[k] !== '' && router.app.optionValues[k] !== false) {
									os[k] = router.app.optionValues[k];
							}
					}

					if (router.app.weight) {
							os.weight = parseFloat(router.app.weight);
					}

					return os;
			},
			weight_pretty: function () {
					if (router.app.weight < 1) {
							return '';
					}
					return window.prettyw(Math.round(router.app.weight * 10) / 10);
			},
			sum: function () {
					var i = router.app.chosenProducts.length,
							s = 0;

					while (i--) {
							s += parseInt(router.app.chosenProducts[i]['pretty_price'].replace(/[\.\s]/g, ''), 10);
					}

					return window.pretty(s);
			}
	},
	methods: {
			resetToStep1: function () {
					// Cancel all ongoing requests
					for (var i in router.app.hijaxers) {
							router.app.hijaxers[i].abort();
							delete router.app.hijaxers[i];
					}
					router.app.hijaxers = {};

					router.push('/');
					if (app.chosenFilters['make']) {
							router.app.clearField(null, 'make');
					}
					if (app.chosenFilters['model']) {
							router.app.clearField(null, 'model');
					}

					router.app.loading = false;
					router.app.mapkits = null;
					router.app.mapkitFilters = null;
					router.app.products = null;
					router.app.chosenProductType = null;
					router.app.chosenProductTypeSystem = null;
					router.app.chosenProductTypeRelations = null;
					router.app.chosenProductTypeHelpName = null;
					router.app.chosenProductTypeHelpDescription = null;
					router.app.chosenProductTypeHelpSections = null;
					router.app.optionGroups = null;
					router.app.optionValues = null;
					app.chosenFilters = [];
					app.chosenFiltersProducttypes = [];
					app.chosenFilterProducts = null;
					app.chosenGroupedProducts = {};
					app.chosenMapkitFilters = {};
					app.weight = 0;

					return false;
			},

			resetToStep2: function () {
					queryParams = {};
					if (router.app.weight) {
							queryParams.weight = router.app.weight;
					}
					router.push({
							name: 'groupings',
							params: {slug: router.app.chosenProductTypeSystem},
							query: router.app.weight ? {weight: router.app.weight} : {}
					});
					return false;
			},

			scrollToTop: function () {
					window.scrollTo(0,0);
			},

			togglePriceChange: function () {
					router.app.displayPriceChange = !router.app.displayPriceChange;
					/* only update products if already loaded */
					if (router.app.products != null)
							getProductsFromBackend();
			},

			getPriceChange: function () {
					hijax('get',
							window.urlPriceChange,
							null, function (data) {
									var jsonData = JSON.parse(data);
									router.app.priceChange = jsonData.item;
							}
					);
					if (router.app.priceChange.changes_available)
							return true;
					else
							return false;
			},

			setProductType: function (selected) {
					var i,
							url,
							elm,
							spoken,
							system,
							relations,
							helpName,
							helpDescription,
							helpSections;

					elm = selected.target;
					while (!elm.dataset.title) {
							elm = elm.parentNode;
					}

					spoken = elm.dataset.title;
					system = elm.dataset.name;

					// FIXME: So ugly...
					i = router.app.productTypes.length;
					while (i--) {
							if (router.app.productTypes[i].name == system) {
									relations = {
											'requirements': router.app.productTypes[i].requirements,
											'recommendations': router.app.productTypes[i].recommendations
									};
									helpName = router.app.productTypes[i].help_sections.name;
									helpDescription = router.app.productTypes[i].help_sections.description;
									helpSections = router.app.productTypes[i].help_sections.items;
							}
					}

					if (relations['requirements'].length < 1)
							relations = 0;

					router.app.chosenProductType = spoken;
					router.app.chosenProductTypeSystem = system;
					router.app.chosenProductTypeRelations = relations;
					router.app.chosenProductTypeHelpName = helpName;
					router.app.chosenProductTypeHelpDescription = helpDescription;
					router.app.chosenProductTypeHelpSections = helpSections;

					url = window.urlProductGroupings.replace('FIXME', router.app.chosenProductTypeSystem);

					if (router.app.weight) {
							url += '?weight=' + router.app.weight;
					}

					hijax('get', url, null, function (data) {
									var items = JSON.parse(data).items;
									if (items.length) {
											// groupings exists, route to groupings.
											router.app.productGroupings = JSON.parse(data).items;
											router.push({
													name: 'groupings',
													params: {slug: router.app.chosenProductTypeSystem},
													query: router.app.weight ? {weight: router.app.weight} : {}
											});
									} else {
											// no groupings found, go straight to products route.
											router.app.productGroupings = false;
											setProductGrouping(router.app.chosenProductTypeSystem);
									}
							}
					);

					router.app.recentProductChosen = null;
			},

			setProductGrouping: function (selected) {
					var elm = selected.target, cpg, cpgp;

					while (!elm.dataset.name) {
							elm = elm.parentNode;
					}

					cpg = elm.dataset.v2 ? elm.dataset.v2 : elm.dataset.v;
					cpgp = elm.dataset.v;

					router.app.optionWCValues = [];

					setProductGrouping(elm.dataset.name, cpg, cpgp);
			},

			filterProducts: function (form) {
					var os = router.app.optionsSet;
					delete os.limit;

					if (router.app.chosenProductGrouping) {
							router.push({name: 'gproducts', query: os});
					} else {
							router.push({name: 'products', query: os});
					}
			},

			filterMapkits: function (form) {
					var visibleMapkits = 0;
					if (router.app.chosenMapkitFilters[event.target.value]) {
							delete router.app.chosenMapkitFilters[event.target.value];
					} else {
							router.app.chosenMapkitFilters[event.target.value] = true;
					}

					var checker = (arr, target) => Object.keys(target).every(v => arr.includes(v))
					for (var index = 0; index < router.app.mapkits.length; index++) {
							var mapkit = router.app.mapkits[index];
							var show = checker(mapkit.functions, router.app.chosenMapkitFilters);
							var mapkitRow = document.querySelector('[data-mapkitId=mapkit-'+mapkit.id);
							if (show) {
									mapkitRow.classList.remove('util-hidden');
									mapkitRow.nextElementSiblinwindow.classList.remove('util-hidden');
							} else {
									mapkitRow.classList.add('util-hidden');
									mapkitRow.nextElementSiblinwindow.classList.add('util-hidden');
							}
					}
					var limitedMapkits = document.querySelectorAll('.mapkit[data-mapkitId]');
					var limitIndex = 0;
					for (var index = 0; index < limitedMapkits.length; index++) {
							if (!limitedMapkits[index].classList.contains('util-hidden')) {
									limitedMapkits[index].classList.remove('expander-hidden');
									limitedMapkits[index].nextElementSiblinwindow.classList.remove('expander-hidden');
									visibleMapkits++;
							}
							limitIndex++;
							if (limitIndex > router.app.productsListingLimit) {
									break;
							}
					}
					var unhider = document.querySelector('.expander-unhider');
					if (unhider) {
							if (visibleMapkits < router.app.productsListingLimit) {
									unhider.classList.add('util-hidden');
							} else {
									unhider.classList.remove('util-hidden');
							}
					}
			},

			loadMoreMapkits: function () {
					var mapkits = document.querySelectorAll('.mapkit[data-mapkitId]');
					var limit = parseInt(event.target.dataset.limit) + router.app.productsLimit;
					var counter = 0;
					for (var i = 0; i < mapkits.length; i++) {
							var mapkit = mapkits[i];
							mapkit.classList.remove('expander-hidden');
							mapkit.nextElementSiblinwindow.classList.remove('expander-hidden');
							counter++;
							if (counter > limit) {
									break;
							}
					}
					if (counter <= limit) {
							event.target.classList.add('util-hidden');
					} else {
							event.target.classList.remove('util-hidden');
					}
					event.target.dataset.limit = limit;
			},

			loadMoreProducts: function (product_type, limit) {
					getProductsFromBackend((router.app.products ? router.app.products.length : null), product_type, limit);
			},

			getTradeshows: function () {
					getTradeshowsFromBackend();
			},

			setOption: function (val, obj) {
					var i, os;

					if (val && val.name) {
							Vue.set(router.app.optionValues, obj.id, val.name);
					} else {
							Vue.set(router.app.optionValues, obj.id, '');
					}

					blurHilightedField();

					os = router.app.optionsSet;
					delete os.limit;

					if (router.app.chosenProductGrouping) {
							router.push({name: 'gproducts', query: os});
					} else {
							router.push({name: 'products', query: os});
					}
			},

			cleanOption: function (obj) {
					router.app.choiceOpen = false;
					var val = obj.target.value, os;

					blurHilightedField();

					if (val != router.app.optionValues[obj.target.id]) {
							Vue.set(router.app.optionValues, obj.target.id, val);

							os = router.app.optionsSet;
							delete os.limit;

							if (router.app.chosenProductGrouping) {
									router.push({name: 'gproducts', query: os});
							} else {
									router.push({name: 'products', query: os});
							}
					}
			},

			cleanFilter: function (obj) {
					router.app.choiceOpen = false;
					// var val = obj.target.value, os;

					// blurHilightedField();

					// if (val != router.app.chosenFilters[obj.target.id]) {
					//     Vue.set(router.app.chosenFilters, obj.target.id, val);

					//     if (router.app.chosenFilters['make']) {
					//         //router.push({name: 'filter', query: { 'make' : router.app.chosenFilters['make'].slug }});
					//     } else {
					//         this.resetToStep1();
					//     }
					// }
			},

			addProductToCart: function (product, product_id) {
					var i, id;

					if (!router.app.chosenProducts) {
							router.app.chosenProducts = [];
					}

					if (!router.app.cartIds) {
							router.app.cartIds = [];
					}

					// Use direct link or index
					if (product_id) {
							id = product_id;
					} else {
							id = router.app.products && router.app.products.length ? router.app.products[product.target.dataset.i].id : product.id;
					}

					i = router.app.chosenProducts.length;
					while (i--) {
							if (router.app.chosenProducts[i].article == id) {
									return;
							}
					}

					if (!product_id) {
							router.app.cartIds.push(id);

							hijax('post', window.urlProspectItems.replace('FIXME', id), 'csrf', function (data) {
									var jsonData = JSON.parse(data);
									if (jsonData.code && jsonData.code == 404) {
											alert('The following product was not found: \n' + jsonData.product);
									} else {
											router.app.chosenProducts.push(jsonData.item);
											router.app.updateRelatedProducts();
									}
							});
					}
					router.app.recentProductChosen = router.app.products && router.app.products.length ? router.app.products[product.target.dataset.i] : product;
					router.app.recentProductChosenTypeSystem = router.app.chosenProductTypeSystem;
			},

			addMapkitToCart: function (mapkit) {
					if (mapkit.products.length && router.app.cartIds.indexOf('mapkit-' + mapkit.id) == -1) {
							hijax('post', window.urlProspectMapkits.replace('FIXME', mapkit.id), 'csrf', function (data) {
									var response = JSON.parse(data);
									var mapkitJson = {
											'id'                :response.item.id,
											'article'           :'mapkit-' + mapkit.id,
											'name'              :mapkit.makemodel.name,
											'designation'       :mapkit.makemodel.name,
											'image_url'         :'/static/images/product-types/accessory-kit.jpg',
											'pretty_price'      :mapkit.pretty_price,
											'requirements'      :[],
											'recommendations'   :[],
											'mapkit'            :true,
											'products'          :mapkit.products
									};
									router.app.recentProductChosen = mapkitJson;
									router.app.cartIds.push('mapkit-'+mapkit.id);
									router.app.chosenProducts.push(mapkitJson);
									router.app.updateRelatedProducts();
							});

							var path = '/' + window.location.search;

							// If there are any article numbers left, they were not found in backend. Alert user
							if (mapkit.invalid_products.length) {
									alert('The following products could not be added: \n' + mapkit.invalid_products.join('\n'));
							}
					}
			},

			toggleCart: function () {
					router.app.displayCart = !router.app.displayCart;
					router.app.getTradeshows();
					router.app.recentProductChosen = null;
			},

			removeProductFromCart: function (selected) {
					var id = selected.target.dataset.id,
							article = parseInt(selected.target.dataset.article, 10);
					if (isNaN(article)) {
							article = selected.target.dataset.article;
					}
					router.app.recentProductChosen = null;

					router.app.cartIds = router.app.cartIds.filter(function (i) {
							return i != article;
					});


					hijax('delete', window.urlProspectItems.replace('FIXME', id), null, function () {
							router.app.chosenProducts = router.app.chosenProducts.filter(function (i) {
									return i.designation.trim() != selected.target.parentNode.parentNode.querySelector('.wrap').textContent.trim();
							});
							router.app.updateRelatedProducts();
					});

					return false;
			},

			emptyCart: function () {
					hijax('delete', window.urlProspect, null, function () {
							router.app.chosenProducts = [];
							router.app.cartIds = [];
							router.app.updateRelatedProducts();
					});
					return false;
			},

			toggleRow: function (selected) {
					var elm = selected.target;
					while (elm.nodeName != 'TR') {
							elm = elm.parentNode;
					}
					elm.classList.toggle('collapsed');
			},

			setCurrentOption: function (val, obj) {
					router.app.choiceOpen = false;
					router.app.currentOption = obj.id;
			},

			setCurrentFilter: function (val, filter, update) {
					router.app.choiceOpen = false;
					var query = {};
					if (router.app.chosenFilters[filter.id] && router.app.chosenFilters[filter.id].slug == val.slug) {
							if (filter.id == 'make' || router.app.chosenFilters[filter.id].generation == val.generation) {
									return;
							}
					}
					// Reset chosen filter products if not chosen anymore
					if (router.app.chosenFilterProducts && !window.location.search.split('products')[1]) {
							router.app.chosenFilterProducts = null;
					}
					if (filter.id == 'make') {
							// Reset model filter
							if (Object.keys(router.app.chosenFilters).length && val.slug != router.app.chosenFilters['make']['slug']) {
									router.app.clearAllFilters();
									// router.app.clearField(null, 'model');
							}
							var matchingMake = false;
							for (var index in router.app.makes) {
									if (router.app.makes[index].slug == val.slug) {
											router.app.chosenFilters[filter.id] = router.app.makes[index];
											matchingMake = true;
											break;
									}
							}
							if (!matchingMake) {
									alert(val.slug + ' was not found');
									return false;
							}

					} else if (filter.id == 'model') {
							var matchingModel = false;
							for (var index in router.app.models) {
									if (router.app.models[index].slug == val.slug && router.app.models[index].generation == val.generation) {
											router.app.chosenFilters[filter.id] = router.app.models[index];
											matchingModel = true;
											break;
									}
							}
							if (!matchingModel) {
									alert(val.slug + ' was not found');
									return false;
							}
					} else if (filter.id == 'producttypes') {
							query['producttypes'] = val.slug;
							router.app.chosenFiltersProducttypes = val.sluwindow.split(',');
					} else {
							router.app.chosenFilters[filter.id] = val;
					}
					setTimeout(() => {
							var target = document.getElementById(filter.id);
							if (target) {
									target.classList.add('set');
									if (router.app.chosenFilters[filter.id] && target.value != router.app.chosenFilters[filter.id]['name']) {
											target.value = filter.id == 'model' ? router.app.chosenFilters[filter.id]['raw_name'] : router.app.chosenFilters[filter.id]['name'];
											target._value = filter.id == 'model' ? router.app.chosenFilters[filter.id]['raw_name'] : router.app.chosenFilters[filter.id]['name'];
									}
							}
					}, 0);
					if (val.weight) {
							router.app.weight = val.weight;
					}

					if (update) {
							getProductsFromBackend();
							getMapkitsFromBackend();

							if (Object.keys(router.app.chosenFilters).length) {
									if (router.app.chosenFilters['make']) {
											query['make'] = router.app.chosenFilters['make']['slug'];
									}
									if (router.app.chosenFilters['model']) {
											query['model'] = router.app.chosenFilters['model']['slug'];
											query['generation'] = router.app.chosenFilters['model']['generation'];
									}
							}
							router.push({
									name: 'types',
									query: query
							});
					}
					if (event && event.type == 'click') {
							var mobileCloseButton = event.target.parentNode.parentNode.parentNode.parentNode.parentNode
							if (mobileCloseButton.className.indexOf('autocomplete-wrapper') !== -1) {
									mobileCloseButton = mobileCloseButton.parentNode;
							}
							mobileCloseButton = mobileCloseButton.querySelector('.btn-close');
							if (mobileCloseButton) {
									mobileCloseButton.click();
							}
					}
					return true;
			},

			setAllFilters: function (filters, skipHistory) {
					for (var f in filters) {
							var filter = filters[f];
							var filterFound = router.app.setCurrentFilter(filter, {'id':filter.id});
							if (!filterFound) {
									return false;
							}
					}

					getProductsFromBackend();
					getMapkitsFromBackend();
			},

			clearAllFilters: function () {
					document.querySelectorAll('.filters--machineFilters .btn-clear').forEach(function(btn) {
							btn.click();
					});
			},

			clearField: function (btn, fieldId) {
					if (!fieldId) {
							var wrapper = btn.target.parentNode;
							if (wrapper.className.indexOf('fld-wrap') === -1) {
									wrapper = wrapper.parentNode;
							}
							var field = wrapper.querySelector('.autocomplete-input');
					}
					var filterId = fieldId ? fieldId : field.id;
					var target = document.getElementById(filterId);
					if (Object.keys(router.app.chosenFilters).length) {

							if (router.app.chosenFilters['make'] && router.app.chosenFilters['model']) {
									getProductsFromBackend();
									getMapkitsFromBackend();
							}

							delete router.app.chosenFilters[filterId];

							blurHilightedField();

					} else if (!fieldId) {
							this.setOption(null, field);
					}
					if (target) {
							target.value = '';
							target._value = null;
							target.classList.remove('set');
					}
					if (fieldId == 'model') {
							this.$children[1].clearInput();
					} else if (fieldId == 'make') {
							this.resetToStep1();
					}
			},

			hilightField: function (fld) {
					router.app.choiceOpen = true;
					router.app.fieldFocused = true;

					if (router.app.optionValues[fld.target.id]) {
							fld.target.value = router.app.optionValues[fld.target.id];
					}

					var i, existing, wrapper = fld.target.parentNode.parentNode;

					// This solves text not being selected on focus in Safari
					// and Chrome.
					fld.target.onmouseup = function () {
							return false;
					};

					if (fld.target.select) {
							fld.target.focus();
							fld.target.select();
					} else {
							fld.target.selectionStart = 0;
							fld.target.selectionEnd = fld.target.value.length;
					}

					existing = document.querySelectorAll('.is-field-hilighted');
					if (existing) {
							i = existing.length;
							while (i--) {
									existing[i].classList.remove('is-field-hilighted');
									existing[i].style.zIndex = '';
							}
					}
					wrapper.classList.add('is-field-hilighted');
					wrapper.style.zIndex = z++;
					setTimeout(function () {
							window.scrollTo(0, 0);
					}, 249);

					return false;
			},

			hilightFilterField: function (fld) {
					router.app.choiceOpen = true;
					router.app.fieldFocused = true;

					if (router.app.machineFilters[fld.target.id]) {
							fld.target.value = router.app.machineFilters[fld.target.id];
					}

					var i, existing, wrapper = fld.target.parentNode.parentNode;

					// This solves text not being selected on focus in Safari
					// and Chrome.
					fld.target.onmouseup = function () {
							return false;
					};

					if (fld.target.select) {
							fld.target.focus();
							fld.target.select();
					} else {
							fld.target.selectionStart = 0;
							fld.target.selectionEnd = fld.target.value.length;
					}

					existing = document.querySelectorAll('.is-field-hilighted');
					if (existing) {
							i = existing.length;
							while (i--) {
									existing[i].classList.remove('is-field-hilighted');
									existing[i].style.zIndex = '';
							}
					}
					wrapper.classList.add('is-field-hilighted');
					wrapper.style.zIndex = z++;
					setTimeout(function () {
							window.scrollTo(0, 0);
					}, 249);

					return false;
			},

			blurField: function (clicked) {
					router.app.fieldFocused = false;
					clicked.target.parentNode.classList.remove('is-field-hilighted');
			},

			sendListForm: function () {
					if (!router.app.acceptTos)
							return false;
					sendFormToBackend(window.urlProspect, prepareFormData());
					return true;
			},

			gotoRelatedProductType: function (clicked) {
					router.app.setProductType(clicked);
			},

			updateRelatedProducts: function () {
					var recommendations = [],
							requirements = [];

					router.app.chosenProducts.forEach(function(e, i, a) {
							if (e.requirements.length) {
									e.requirements.forEach(function(f, j, b) {
											requirements.push(f);
									});
							}
							if (e.recommendations.length) {
									e.recommendations.forEach(function(f, j, b) {
											recommendations.push(f);
									});
							}
					});

					function uniq(e, i, a) {
							var found = a.findIndex(function(f, j, b) {
									return e.related_type.name === f.related_type.name;
							});
							return found === i;
					};

					function notInCart(e, i, a) {
							return router.app.chosenProducts.findIndex(function(f, j, b) {
									return e.related_type.name === f.product_type;
							}) === -1;
					};

					requirements = requirements.filter(uniq).filter(notInCart);
					recommendations = recommendations.filter(uniq).filter(notInCart);

					router.app.chosenProductsRelationsRequirements = requirements;
					router.app.chosenProductsRelationsRecommendations = recommendations;
			},

			/* Return true if given product type is required by any other product */
			productIsRequired: function(product_type) {
					var found = false;

					router.app.chosenProducts.forEach(function(e, i, a) {
							if (e.requirements.length) {
									e.requirements.forEach(function(f, j, b) {
											if (f.related_type.name == product_type) {
													found = true;
											}
									});
							}
					});

					return found;
			},

			sendSpecForm: function () {
					var url,
							os = router.app.optionsSet,
							q = [],
							i;

					if (!router.app.acceptTos)
							return false;

					for (i in os) {
							if (os.hasOwnProperty(i)) {
									q.push(i + '=' + encodeURIComponent(os[i]));
							}
					}
					url = window.urlSpec.replace('FIXME', router.app.chosenProductTypeSystem) + '?' + q.join('&');
					sendFormToBackend(url, prepareFormData());
					return true;
			},

			sendSpecToEmail: function () {
					if (!router.app.acceptTos)
							return false;
					hijax('post', window.urlProspect, 'receiver=' + router.app.form_email + '&comment=' + encodeURIComponent(router.app.form_comment), function (res) {
							router.app.formErrors = [];
							router.app.success = true;
							window.scrollTo(0, 0);
							router.app.form_email = null;
							router.app.form_comment = null;
					}, function (code, res) {
							var errors = JSON.parse(res), fields = [];

							if ('receiver' in errors) {
									fields.push('email_required');
							}

							router.app.formErrors = fields;
					});
					return true;
			},

			// Translate strings not available at render (like bound variables)
			jstrans: function(string) {
					return gettext(string)
			}
	}
})

<template>
    <table class="cart-table">
        <tbody v-if="this.visible" >
            <tr class="truncated mapkit" v-bind:data-functions="mapkit.functions">
                <td @click="toggleRow">{% verbatim %}{{ mapkit.id }}{% endverbatim %} <b><b></b></b></td>
                <td class="var truncated">
                    <div class="truncated-primary">{% verbatim %}{{ mapkit.makemodel.name }}{% endverbatim %}</div>
                    <div class="truncated-secondary">{% verbatim %}{{ mapkit.functions_string }}{% endverbatim %}</div>
                </td>
                <td class="tar">
                    <strong v-if="mapkit.ready">
                        {% verbatim %}{{ mapkit.pretty_price }}{% endverbatim %}
                    </strong>
                    <em v-else>{% trans 'På gång' %}</em>
                    <span
                    v-if="cartIds.indexOf('mapkit-'+mapkit.id) == -1" role="button"
                    class="btn btn-sm btn-add"
                    @click="addMapkitToCart(mapkit)"
                    v-bind:data-i="'mapkit-'+mapkit.id"
                    v-bind:class="{'btn-disabled': (!mapkit.ready || mapkit.total_price == 0) }">
                        {% trans 'Välj' %}
                    </span>
                    <span v-else disabled
                    class="btn btn-sm btn-add btn-disabled"
                    v-bind:data-i="'mapkit-'+mapkit.id">
                        {% trans 'Vald' %}
                    </span>
                </td>
            </tr>
            <tr class="collapsed-extra" v-bind:class="{'expander-hidden': $index > 1 }">
                <td></td>
                <td colspan="3">
                    <ul class="fold">
                        <strong>{% trans 'Funktioner' %}:</strong>
                        <li v-for="(mapkitFunction, key) in mapkit.functions" :key="key">
                            {% verbatim %}{{ mapkitFunction }}{% endverbatim %}
                        </li>
                    </ul>
                    <ul class="fold">
                        <li>
                            <strong>{% trans 'Artiklar' %}:</strong>
                            <table>
                                <tr v-for="(product, key) in mapkit.products" :key="key">
                                    <td class="tal">{% verbatim %}{{ product.id }}{% endverbatim %}</td>
                                    <td>{% verbatim %}{{ product.name }}{% endverbatim %}</td>
                                    <td style="white-space:nowrap;">{% verbatim %}{{ product.pretty_price }}{% endverbatim %}</td>
                                </tr>
                            </table>
                        </li>
                        <li v-if="mapkit.invalid_products != ''">
                            <strong>{% trans 'Okända artiklar' %}:</strong>
                            <div v-for="(product, key) in mapkit.invalid_products" :key="key">
                                {% verbatim %}{{ product }}{% endverbatim %}
                            </div>
                        </li>
                    </ul>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
	export default {
		props: ['mapkit', 'filters'],
		computed: {
			visible: () => this.mapkit.every(v => this.filters.includes(v))
		}
	}
</script>